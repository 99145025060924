/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Button, IconRatingsStarFilled, IconRatingsStarHalf, IconRatingsStarUnfilled, Pagination, Select, SelectOption } from '@lmig/lmds-react';
import useEnvironmentalizeLink from "@lmig/dotcom-aspect-hooks/useEnvironmentalizeLink";
import Heading from '@lmig/dotcom-aspect-components/Utility/Heading';
import Review from './Review';
import Rating from './Rating';

const Reviews = ({
  googleWriteReview = '',
  comparionWriteReview = '',
  totalRating = 5,
  allReviews = [],
  name = undefined,
}) => {
  const [itemCount, setItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentReviews, setCurrentReviews] = useState([]);
  const googleReviewLink = useEnvironmentalizeLink({
    domain: "GOOGLE_SEARCH",
    link: `/local/writereview?placeid=${googleWriteReview}`,
  });
  const comparionReviewLink = comparionWriteReview;

  useEffect(() => {
    setItemCount(allReviews.length);
    setCurrentReviews(allReviews.slice(0, 3));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPaginate = (e) => {
    setCurrentReviews(allReviews.slice(e.page * 3, (e.page + 1) * 3));
    setCurrentPage(e.page);
    document.getElementById("reviews").scrollIntoView({ behavior: 'instant' });
  };

  const onFilterChange = (e) => {
    let sortedReviews = [];

    switch (e.target.value) {
      case 'oldest':
        sortedReviews = allReviews.sort((a, b) => a.publisherDate - b.publisherDate);
        setCurrentReviews(sortedReviews.slice(0, 3));
        setCurrentPage(0);
        break;
      case 'newest':
        sortedReviews = allReviews.sort((a, b) => b.publisherDate - a.publisherDate);
        setCurrentReviews(sortedReviews.slice(0, 3));
        setCurrentPage(0);
        break;
      case 'lowest':
        sortedReviews = allReviews.sort((a, b) => a.rating - b.rating);
        setCurrentReviews(sortedReviews.slice(0, 3));
        setCurrentPage(0);
        break;
      case 'highest':
        sortedReviews = allReviews.sort((a, b) => b.rating - a.rating);
        setCurrentReviews(sortedReviews.slice(0, 3));
        setCurrentPage(0);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Heading id="reviews" marginBottom="none" align="center" tag="h2" type="h2" >
        <strong>Reviews</strong> about {name}
      </Heading>
      <div id="reviewsComponent">
        <div className='hiddenStars'>
          <IconRatingsStarFilled size="16" className="star" id="lmds-filled" />
          <IconRatingsStarHalf size="16" className="star" id="lmds-half-filled" />
          <IconRatingsStarUnfilled size="16" className="star" id="lmds-not-filled" />
        </div>
        <div className="reviewsBox">
          <div className="reviewsHeader">
            <div className="ratingWrapper">
              <div className="headerLabel">Overall rating</div>
              <Rating rating={totalRating} count={allReviews.length} type="agentOfficeReviews" showText={false} />
            </div>
            <div className="totalWrapper">
              <div className="headerLabel">Total reviews</div>
              <div>{allReviews.length === 1 ? `1 review` : `${allReviews.length} reviews`}</div>
            </div>
            <div data-theme="evolved" className="filterWrapper">
              <Select labelVisual="Sort by" defaultValue="Newest" onChange={onFilterChange} disabled={allReviews.length === 0} data-testid="sortBy" name="sortBy">
                <SelectOption value="newest" data-testid="newest">Newest</SelectOption>
                <SelectOption value="oldest" data-testid="oldest">Oldest</SelectOption>
                {totalRating !== 5 && <>
                  <SelectOption value="highest" data-testid="highest">Highest ratings</SelectOption>
                  <SelectOption value="lowest" data-testid="lowest">Lowest ratings</SelectOption>
                </>}
              </Select>
            </div>
          </div>
          {allReviews?.length > 0 ?
            <div className="reviewsContent">
              {currentReviews.map(review => (<Review key={`${review.authorName}-${review.publisherDate}`} review={review} schema />))}
              {allReviews.length > 3 && <div data-theme="evolved">
                <Pagination className="pagination" itemCount={itemCount} withWords={false} onChange={onPaginate} page={currentPage} showItemsPerPage={false} itemsPerPage={3} data-testid="reviewPaginate" />
              </div>}
            </div>
            :
            <div className="noReviewsYet">No reviews yet
              <hr className="noReviewsHr" />
            </div>}
          <div className="leaveAReviewWrapper">
            {googleWriteReview && <div data-theme="evolved"><Button href={googleReviewLink} aria-label="write a google review" id="googleReviewButton" variant="primary">Leave a Google review</Button></div>}
            {comparionWriteReview && <div data-theme="evolved" className="comparionButton"><Button href={comparionReviewLink} aria-label="write a comparion review" id="comparionReviewButton">Write a Comparion review</Button></div>}
          </div>
        </div>

        <style global jsx>{`
          #reviews{
            margin-top: 3rem;
            @media screen and (min-width: 41rem) {
              margin-top: 4rem;
            }
            @media screen and (min-width: 48rem) {
              margin-top: 5rem;
            }
          }
          #reviewsComponent{
            display: flex;
            justify-content: center;
            margin-top: 3rem;

            .hiddenStars{
              display: none;
            }
            .lm-Icon.star{
              margin-bottom: 0.5rem;
            }

            .reviewsBox{
              box-shadow: 0px 0px 4px 0px #0000000F, 0px 4px 4px -2px #00000029, 0px 5px 8px -2px #00000014, 0px 7px 12px -2px #0000000A;
              border-radius: 1rem;
              max-width: 52rem;
              width: 100%;
              margin-bottom: 3rem;
              @media screen and (min-width: 41rem) {
                margin-bottom: 4rem;
              }
              @media screen and (min-width: 48rem) {
                margin-bottom: 5rem;
              }
            }

            .reviewsHeader{
              padding: 2rem 2rem 0 2rem;
              background-color: #F2FCFC;
              border-radius: 1rem 1rem 0 0;
              display: flex;
              flex-direction: column;

              @media screen and (min-width: 41rem) {
                justify-content: space-between;
                flex-direction: row;
              }
            }

            .reviewsContent{
              padding: 1rem;

              @media screen and (min-width: 41rem) {
                padding: 1rem 2rem;
              }
            }

            .filterWrapper{
              width: 100%;
              margin-top: 1rem;

              @media screen and (min-width: 41rem) {
                margin-top: unset;
                max-width: 13.125rem;
              }
            }

            .headerLabel{
              font-size: 1.25rem;
              color: #343741;
              font-weight: 700;
            }

            .leaveAReviewWrapper{
              display: flex;
              justify-content: center;
              margin-bottom: 2rem;
              flex-flow: column;
              padding: 0 1rem;

              @media screen and (min-width: 41rem) {
                flex-flow: row;
                padding: unset;
              }
            }

            .lmig-Pagination.pagination{
              margin-top: 1rem;
              width: 21rem;
              justify-content: center;

              @media screen and (min-width: 41rem) {
                justify-content: flex-end;
                width: unset;
              }
            }

            .comparionButton{
              margin: 1rem 0 0 0;

              @media screen and (min-width: 41rem) {
                margin: 0  0 0 1rem;
              }
            }

            .noReviewsYet{
              margin: 2rem;
            }

            .noReviewsHr{
              max-width: 48rem;
              border: none;
              background-color: #C0BFC0;
              height: 1px;
              margin-top: 2rem;
            }
          }
        `}</style>
      </div>
    </>
  );
};

Reviews.propTypes = {
  googleWriteReview: PropTypes.string,
  comparionWriteReview: PropTypes.string,
  totalRating: PropTypes.number,
  allReviews: PropTypes.arrayOf(PropTypes.shape({
    rating: PropTypes.number,
    content: PropTypes.string,
    authorName: PropTypes.string,
    publisherDate: PropTypes.number,
    reviewLanguage: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.shape({
      publisherDate: PropTypes.number,
      authorName: PropTypes.string,
      authorRole: PropTypes.string,
      authorEmail: PropTypes.string,
      content: PropTypes.string,
      visibility: PropTypes.string
    })),
  })),
  name: PropTypes.string,
};

export default Reviews;
